<template>
  <div class="complaint">
    <div class="top_bar">
      <div class="top_bar_icon" @click="handleRouter">
        <van-icon name="arrow-left" color="#7e7e7e" size="16" />
      </div>
      <div>填写申诉信息</div>
    </div>
    <div class="reason">
      <div>
        <span class="red">*</span>
        <span>申诉原因：</span>
      </div>
      <div>
        <span ref="radioSelect">请选择</span>
        <img src="../../assets/image/right.svg" alt @click="show = true" />
      </div>
    </div>
    <div class="reason groud">
      <div>
        <span class="red">*</span>
        <span>申诉说明：</span>
      </div>
    </div>
    <div class="inputs">
      <textarea class="text" placeholder="请输入内容" v-model="inputs"></textarea>
    </div>
    <div class="check">
      <img :src="[check?checked:no_checked]" alt @click="check=!check" />
      <!-- <img src="../../assets/image/checked.svg" alt=""> -->
      <div class="text">
        我已阅读并接受
        <span @click="onClick">《申诉说明》</span>，并确定以上提交的为真实信息，对内容提供相关证明。
      </div>
    </div>
    <div class="btn btn_top" @click="handleTo">提交申诉</div>
    <!-- 弹出 -->
    <van-popup v-model="show" position="bottom" :style="{ height: '417px' }">
      <div class="pupup">
        <div class="popup_title">申诉原因</div>
        <van-radio-group v-model="radio" @change="handleChange">
          <van-cell-group :border="false">
            <van-cell title="少发/漏发/发错产品" clickable>
              <van-radio name="1">
                <template #icon="props">
                  <img :src="props.checked ? checked : no_checked" />
                </template>
              </van-radio>
            </van-cell>
            <van-cell title="包装/商品破损/污渍" clickable>
              <van-radio name="2">
                <template #icon="props">
                  <img :src="props.checked ? checked : no_checked" />
                </template>
              </van-radio>
            </van-cell>
            <van-cell title="假冒品牌商品" clickable>
              <van-radio name="3">
                <template #icon="props">
                  <img :src="props.checked ? checked : no_checked" />
                </template>
              </van-radio>
            </van-cell>
            <van-cell title="虚假发货/没有收到货" clickable>
              <van-radio name="4">
                <template #icon="props">
                  <img :src="props.checked ? checked : no_checked" />
                </template>
              </van-radio>
            </van-cell>
            <van-cell title="其他" clickable>
              <van-radio name="5">
                <template #icon="props">
                  <img :src="props.checked ? checked : no_checked" />
                </template>
              </van-radio>
            </van-cell>
          </van-cell-group>
        </van-radio-group>
        <div class="btn btn_t" @click="show = false">关闭</div>
      </div>
    </van-popup>
  </div>
</template>
<script>
export default {
  data() {
    return {
      check: false,
      no_checked: require("../../assets/image/no_checked.svg"),
      checked: require("../../assets/image/checked.svg"),
      show: false,
      radio: "1",
      text: [
        {
          name: "1",
          title: "少发/漏发/发错产品"
        },
        {
          name: "2",
          title: "包装/商品破损/污渍"
        },
        {
          name: "3",
          title: "假冒品牌商品"
        },
        {
          name: "4",
          title: "虚假发货/没有收到货"
        },
        {
          name: "5",
          title: "其他"
        }
      ],
      orderId: "",
      inputs: ""
    };
  },
  methods: {
    handleRouter() {
      this.$router.go(-1);
    },
    handleChange(name) {
      this.radio = name;
      console.log(this.radio, "radio");
      for (let item = 0; item < this.text.length; item++) {
        if (name == this.text[item].name) {
          this.$refs.radioSelect.innerHTML = this.text[item].title;
        }
      }
      this.show = false;
    },
    async handleTo() {
      if (this.check) {
        if (this.inputs === "") {
          this.$notify({ type: "danger", message: "请填写申诉说明！" });
          return;
        }
        const data = await this.$api.complain({
          order_id: this.orderId,
          content: this.inputs,
          reason_id: this.radio
        });
        if (data.data.code === 1) {
          this.$notify({ type: "success", message: "申诉成功！" });
          setTimeout(() => {
            this.$router.push({
              path: "/order/index"
            });
          }, 1000);
        } else {
          this.$notify({ type: "danger", message: data.data.msg });
        }
      } else {
        this.$notify({
          type: "danger",
          message: "未勾选《申诉说明》"
        });
      }
    },
    onClick(){
      this.$router.push({
          path: "/rules/index",
          query: {
            type: 3
          }
        });
    }
  },
  mounted() {
    this.orderId = this.$route.query.orderId;
  }
};
</script>
<style lang="scss" scoped>
.complaint {
  width: 375px;
  height: auto;
  padding-top: 40px;
  box-sizing: border-box;
  .reason {
    width: 351px;
    height: 45px;
    font-size: 15px;
    line-height: 15px;
    border-bottom: 1px solid #ebebeb;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    div {
      .red {
        color: rgba(250, 46, 46, 1);
        margin-right: 5px;
      }
      img {
        width: 11px;
        height: 11px;
        margin-left: 10px;
      }
    }
  }
  .groud {
    border: none;
  }
  .inputs {
    width: 331px;
    margin: 0 auto;
    .text {
      width: 331px;
      height: 153px;
      background: rgba(249, 249, 249, 1);
      border-radius: 5px;
      border: none;
      border: 1px solid rgba(222, 222, 222, 1);
      font-size: 15px;
      line-height: 20px;
      padding-top: 15px;
      padding-left: 15px;
      box-sizing: border-box;
    }
  }
  .check {
    width: 375px;
    margin-top: 20px;
    height: 32px;
    padding-left: 11px;
    padding-right: 39px;
    box-sizing: border-box;
    display: flex;
    align-items: flex-start;
    img {
      width: 12px;
      height: 12px;
      margin-right: 5px;
      margin-top: 2px;
    }
    .text {
      font-size: 10px;
      color: rgba(51, 51, 51, 1);
      line-height: 16px;

      span {
        color: #00b100;
      }
    }
  }
  .btn_top {
    margin-top: 132px;
  }
  .pupup {
    width: 100%;
    height: 417px;
    padding-top: 20px;
    box-sizing: border-box;
    .popup_title {
      width: 68px;
      height: 17px;
      font-size: 17px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: rgba(51, 51, 51, 1);
      line-height: 17px;
      margin: 0 auto;
    }
    .btn_t {
      margin-top: 20px;
    }
  }
}
</style>